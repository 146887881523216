<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" lg="10">

          <v-alert v-if="Object.keys(errors).length" border="top" dismissible prominent type="error">
            <ul class="fs-15">
              <li v-for="(err,index) in errors" :key="index">
                {{ err.toString() }}
              </li>
            </ul>
          </v-alert>

          <Overlay :overlay="isLoading"/>

          <InfoPlanning v-if="step === 0"
                        :form.sync="form"
                        :step.sync="step"
                        @next="step = 1"/>

          <Animators v-if="step === 1"
                     :form.sync="form"
                     :step.sync="step"
                     @back="step = 0"
                     @next="step = 2"/>

          <Scenarios v-if="step === 2"
                     :gifts="gifts"
                     :form.sync="form"
                     :step.sync="step"
                     @back="step = 1"
                     @save="save"/>


          <ConfirmDialog ref="confirmDialog"/>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import InfoPlanning from "@/views/v2_campaigns/create/components/InfoPlanning.vue";

import Animators from "@/views/v2_campaigns/create/components/Animators.vue";
import {HTTP} from "@/http-common";
import Scenarios from "@/views/v2_campaigns/create/components/Scenarios";

export default {
  components: {Scenarios, Animators, InfoPlanning},
  data() {
    return {
      isLoading: false,
      errors: {},
      gifts: [],
      step: 0,
      form: {
        id: null,
        name: null,
        start_date: null,
        end_date: null,
        description: null,
        color: '#23b7e5',
        image: null,
        animators: [],
        scenarios: [{
          name: null,
          min_product_qty: 1,
          brands: [],
          products: [],
          purchased_products: [],
          purchased_brands: [],
          gifts: [],
        }],
      }
    }
  },
  methods: {
    async save() {
      if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir enregistrer cette campagne ?', {
        color: 'orange',
        agreeText: 'Enregistrer',
        icon: 'mdi-information-outline',
      })) {
        this.isLoading = true
        this.errors = {}
        let url = this.form.id ? "/v2-campaigns/update" : "/v2-campaigns/store"

        HTTP.post(url, this.form).then(() => {
          this.$successMessage = "Cette campagne a été enregistré avec succés"
          this.isLoading = false
          this.$router.push('/v2-campaigns')
        }).catch(err => {
          console.log(err)
          this.isLoading = false
          this.$vuetify.goTo(0)
          this.errors = err.response.data.errors
          this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        })
      }
    },
    showCampaign() {
      this.isLoading = true
      HTTP.get('/v2-campaigns/' + this.$route.params.id + '/show').then((res) => {
        this.isLoading = false
        if (res && res.data && !res.data.data) {
          this.$router.push('/404')
        } else {
          let campaign = res.data.data

          this.form = {
            id: campaign.id,
            name: campaign.name,
            start_date: campaign.start_date,
            end_date: campaign.end_date,
            description: campaign.description,
            color: campaign.color,
            image: campaign.image,

            scenarios: campaign.scenarios.map(el => {
              return {
                id: el.id,
                name: el.name,
                min_product_qty: el.min_product_qty,
                brands: el.original_brands.map(item => item.id),
                products: el.original_products.map(item => item.id),
                purchased_products: el.purchased_products.map(item => item.id),
                purchased_brands: el.purchased_brands.map(item => item.id),

                gifts: this.gifts.map(gift => {
                  let giftIndex = el.gifts.findIndex(g => g.id === gift.id);
                  let giftData = giftIndex !== -1 ? el.gifts[giftIndex] : null;

                  return {
                    id: gift.id,
                    image: gift.image,
                    name: gift.name,
                    is_digital: gift.is_digital,
                    type: gift.type,
                    percentage: giftData ? giftData.pivot.percentage : null,
                    digital_amount: giftData ? giftData.pivot.digital_amount : null,
                    recipient_digital_amount: giftData ? giftData.pivot.recipient_digital_amount : null,
                    is_active: !!giftData
                  };
                }),
              };
            }),

            animators: campaign.animators.map(el => ({
              ...el,
              is_active: true
            })),
          };


        }

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    getGifts() {
      this.isLoading = true
      HTTP.get('/gifts').then((res) => {
        this.isLoading = false

        /**
         * Spin To Win
         * @type {any}
         */

        this.gifts = res.data.data

        this.form.scenarios.map(el => {
          el['gifts'] = JSON.parse(JSON.stringify(res.data.data.map(el => {
            el['percentage'] = null
            el['digital_amount'] = null
            el['recipient_digital_amount'] = null
            el['is_active'] = false
            return el
          })))
        })


      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  mounted() {
    this.getGifts()
    if (this.$route.params.id && this.$route.name === 'v2-campaigns.update') {
      this.showCampaign()
    }
  }
}
</script>

<style scoped>

</style>