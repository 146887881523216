<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="primary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-card-text class="pt-10 px-15">

        <v-text-field v-model="form.name" dense label="Nom *" outlined/>

        <v-row>

          <v-col>
            <v-menu v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="form.start_date"
                              clearable
                              dense
                              label="Date de début *"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:prepend-inner="menu1 = true"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.start_date"
                             :min="dateNow"
                             @change="[menu1 = false,form.end_date='']"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu v-model="menu2"
                    :close-on-content-click="false"
                    max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="form.end_date"
                              :disabled="!form.start_date"
                              clearable
                              dense
                              label="Date de fin *"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:prepend-inner="menu2 = true"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.end_date"
                             :min="form.start_date"
                             @change="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-textarea v-model="form.description"
                    dense
                    label="Description"
                    outlined
                    rows="2"/>

        <v-row>

          <v-col class="d-flex flex-column" cols="12" lg="6">
            <v-card class="rounded-lg flex d-flex flex-column" outlined>
              <v-card-text class="mb-0 pb-0 flex">
                <v-color-picker v-model="form.color" hide-canvas hide-mode-switch mode="hexa" width="100%"/>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col class="d-flex flex-column" cols="12" lg="6">
            <v-card class="rounded-lg flex d-flex flex-column" outlined>
              <v-card-text class="mb-0 pb-0 flex">
                <v-layout align-center="" class="text-center" justify-center="">
                  <v-flex shrink>
                    <div class="profile-img">

                      <v-img v-if="isUpload"
                             :src="form.image ? form.image : require('@/assets/no-image.png')"
                             class="rounded-circle"
                             height="90"
                             width="90">
                      </v-img>


                      <v-img v-else
                             :src="form.image ? ($route.params.id ? $baseUrl+form.image : form.image ): require('@/assets/no-image.png')"
                             class="rounded-circle"
                             height="90"
                             width="90">
                      </v-img>

                    </div>

                    <v-btn v-if="!form.image"
                           class="btn-upload"
                           color="primary"
                           dark
                           depressed
                           fab
                           x-small
                           @click="onButtonClick">
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>

                    <v-btn v-if="form.image"
                           class="btn-upload"
                           color="red"
                           dark
                           depressed
                           fab
                           x-small
                           @click="form.image = null">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>

                  </v-flex>
                </v-layout>

                <input ref="image"
                       accept="image/png,image/jpeg"
                       class="d-none"
                       type="file"
                       @change="uploadImage($event)">
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>


      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="secondary"
               depressed
               @click="$emit('next')">
          Suivant
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import Tabs from "@/views/v2_campaigns/create/components/Tabs.vue";

export default {
  props: {
    step: {
      type: Number
    },
    form: Object
  },
  components: {Tabs},
  data() {
    return {
      progress: 0,
      menu1: false,
      menu2: false,
      isUpload: false,
      dateNow: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },
  methods: {
    uploadImage(event) {
      let files = event.target.files
      let file = files[0]
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.isUpload = true
          this.form.image = reader.result
        }
        reader.readAsDataURL(file)
      }
    },
    onButtonClick() {
      window.addEventListener('focus', () => {
      }, {once: true})
      this.$refs.image.click()
    },
  },
  mounted() {
    this.$vuetify.goTo(0)
    let _vm = this
    setTimeout(function () {
      _vm.progress += 33
    }, 500)
  },
  computed: {
    stepVal: {
      get: function () {
        return this.step;
      },
      set: function (newValue) {
        this.$emit('update:step', newValue)
      }
    },
  }
}
</script>

<style scoped>
.profile-img {
  border: solid 1px #e9ebec;
  border-radius: 50%;
  padding: 5px;
}

.btn-upload {
  margin-right: -75px;
  margin-top: -50px
}
</style>