<template>
  <div>

    <v-card flat tile>
      <v-card-title>
        {{ $route.params.id ? "Modifier une campagne" : "Nouvelle campagne" }}
        <v-spacer></v-spacer>

        <v-btn text color="primary" to="/v2-campaigns">
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
      </v-card-title>

      <v-card-subtitle>
        Tous les champs indiqués avec une étoile <span class="red--text">(*)</span> sont obligatoires.
      </v-card-subtitle>
    </v-card>

    <v-divider class="mb-8"></v-divider>

    <v-tabs v-model="stepVal" centered>

      <v-tab active-class="active-tab" class="text-none pointer-none">
        <v-icon left>mdi-clock</v-icon>
        Info&Plannification
      </v-tab>

      <v-tab active-class="active-tab" class="text-none pointer-none">
        <v-icon left>mdi-account-group-outline</v-icon>
        Animateurs
      </v-tab>

      <v-tab active-class="active-tab" class="text-none pointer-none">
        <v-icon left>mdi-script-text-outline</v-icon>
        Scénarios
      </v-tab>

    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    step: {
      type: Number
    }
  },
  computed: {
    stepVal: {
      get: function () {
        return this.step;
      },
      set: function (newValue) {
        this.$emit('update:step', newValue)
      }
    },
  }
}
</script>

<style scoped>
.pointer-none {
  /*pointer-events: none*/
}
</style>