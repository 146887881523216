<template>
  <div>
    <v-card class="shadow rounded-lg">
      <v-progress-linear :value="progress" color="primary" height="7" striped></v-progress-linear>

      <Tabs :step.sync="stepVal"/>

      <v-card-text class="pt-10 px-15">

        <v-card outlined
                class="mb-7"
                v-for="(scenario,i) in form.scenarios"
                :key="i">

          <v-btn depressed
                 color="warning"
                 fab
                 dark
                 small
                 absolute
                 top
                 right
          >
            <span class="fs-15">{{ i + 1 }}</span>
          </v-btn>

          <v-card-text class="pa-10">

            <v-row>
              <v-col>
                <v-text-field v-model="scenario.name"
                              dense
                              label="Nom du scénario *"
                              outlined
                />
              </v-col>
              <v-col>
                <v-text-field v-model="scenario.min_product_qty"
                              dense
                              type="number"
                              label="Quantité minimale du produit pour gagner un cadeau *"
                              outlined
                />
              </v-col>
            </v-row>

            <div class="bg-pink-subtle pa-4 rounded-lg">

              <h4 class="font-weight-medium mb-2">
                Produits originaux
              </h4>

              <div v-if="isLoading" class="d-flex mb-3">
                <v-skeleton-loader v-for="item in 5" :key="item" class="mx-1" type="chip"/>
              </div>

              <v-chip-group v-else
                            v-model="scenario.brands"
                            class="mb-3"
                            color="primary"
                            column
                            multiple
              >
                <v-chip v-for="(brand,i) in brands"
                        :key="i"
                        :value="brand.id"
                        color="primary"
                        filter
                >
                  {{ brand.name }}
                </v-chip>
              </v-chip-group>

              <v-autocomplete v-model="scenario.products"
                              :disabled="isLoading"
                              :items="scenario.brands.length ? products.filter(product =>
                                   !scenario.brands.length ||
                                    scenario.brands.includes(product.brand_id)
                                  ) : []"
                              :loading="isLoading"
                              chips
                              class="autocomplete"
                              deletable-chips
                              dense
                              hide-details
                              item-text="name"
                              item-value="id"
                              label="Produits *"
                              multiple
                              outlined
                              small-chips
              >
              </v-autocomplete>

              <v-btn small
                     :disabled="!scenario.brands.length"
                     class="mt-2 v-btn--active"
                     color="primary"
                     text
                     @click="selectAllProducts(i)">
                Sélectionner tout
              </v-btn>

            </div>

            <div class="mt-3 pa-4  bg-orange-subtle rounded-lg">

              <h4 class="font-weight-medium mb-2">
                Produits achetés
              </h4>

              <div v-if="isLoading" class="d-flex mb-3">
                <v-skeleton-loader v-for="item in 5" :key="item" class="mx-1" type="chip"/>
              </div>

              <v-chip-group v-else
                            v-model="scenario.purchased_brands"
                            class="mb-3"
                            color="primary"
                            column
                            multiple
              >
                <v-chip v-for="(brand,i) in brands"
                        :key="i"
                        :value="brand.id"
                        color="primary"
                        filter
                >
                  {{ brand.name }}
                </v-chip>
              </v-chip-group>

              <v-autocomplete v-model="scenario.purchased_products"
                              :disabled="isLoading"
                              :items="scenario.purchased_brands.length ? products.filter(product =>
                                   !scenario.purchased_brands.length ||
                                    scenario.purchased_brands.includes(product.brand_id)
                                  ) : []"
                              :loading="isLoading"
                              chips
                              class="autocomplete"
                              deletable-chips
                              hide-details
                              dense
                              item-text="name"
                              item-value="id"
                              label="Produits *"
                              multiple
                              outlined
                              small-chips
              >
              </v-autocomplete>
              <v-btn small
                     :disabled="!scenario.purchased_brands.length"
                     class="mt-2 v-btn--active"
                     color="primary"
                     text
                     @click="selectAllPurchasedProducts(i)">
                Sélectionner tout
              </v-btn>
            </div>

            <div class="mt-3 pa-4 bg-blue-subtle rounded-lg">

              <h4 class="font-weight-medium mb-2">
                Cadeaux
              </h4>

              <v-skeleton-loader v-if="isLoading"
                                 type="list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"/>

              <v-simple-table v-if="!isLoading"
                              class="rounded-lg table-border" height="300" fixed-header>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th></th>
                    <th>Cadeau</th>
                    <th>Pourcentage & Montant & Destinataire</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(gift,index) in scenario.gifts" :key="index"
                      :class="gift.is_active ? 'v-data-table__selected' : ''">
                    <td>
                      <v-checkbox v-model="gift.is_active"
                                  :false-value="false"
                                  :true-value="true"
                                  class="pa-0 ma-0"
                                  color="secondary"
                                  hide-details
                      />
                    </td>
                    <td>
                      <div class="d-flex align-center py-3">
                        <v-img :src="$baseUrl + gift.image"
                               class="rounded zoom-pointer"
                               contain
                               max-height="40"
                               max-width="40"
                               min-height="40"
                               min-width="40"
                               @click="$refs.lightbox.open(gift.image)"></v-img>
                        <span class="ml-2">{{ gift.name }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex">
                        <v-text-field v-if="gift.is_digital"
                                      v-model="gift.digital_amount"
                                      v-number
                                      dense
                                      hide-details
                                      outlined
                                      placeholder="Montant *"
                                      suffix="DZD"/>
                        <span v-if="gift.is_digital"> &nbsp;</span>
                        <v-select v-if="gift.is_digital"
                                  v-model="gift.recipient_digital_amount"
                                  :items="recipients"
                                  dense
                                  hide-details
                                  item-text="name"
                                  item-value="value"
                                  outlined
                                  placeholder="Destinataire *"
                        />
                        <span v-if="gift.is_digital"> &nbsp;</span>
                        <v-text-field v-model="gift.percentage"
                                      v-number
                                      dense
                                      hide-details
                                      outlined
                                      placeholder="Pourcentage *"
                                      suffix="%"/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </template>

              </v-simple-table>

            </div>


          </v-card-text>
          <v-divider/>

          <v-card-actions>
            <v-spacer/>
            <v-btn icon color="red"
                   :disabled="form.scenarios.length === 1"
                   depressed
                   @click="form.scenarios.splice(i,1)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <div class="text-center" @click="addScenario">
          <v-btn fab depressed color="primary">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>

      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn color="primary"
               text
               @click="$emit('back')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Retour
        </v-btn>
        <v-spacer/>
        <v-btn color="primary"
               depressed
               @click="$emit('save')">
          Enregistrer
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>
import Tabs from "@/views/v2_campaigns/create/components/Tabs.vue";
import axios from "axios";
import {HTTP} from "@/http-common";

export default {
  props: {
    step: {
      type: Number
    },
    form: Object,
    gifts: Array,
  },
  components: {Tabs},
  data() {
    return {
      isLoading: false,
      progress: 66,
      brands: [],
      products: [],
      recipients: [
        {name: 'Consommateur', value: 'consumer'},
        {name: 'Point de vente', value: 'salepoint'},
        {name: 'Animateur', value: 'animator'},
      ],
    }
  },
  methods: {
    filteredProducts(i) {
      return this.form.scenarios[i].brands.length
          ? this.products.filter(product =>
              this.form.scenarios[i].brands.includes(product.brand_id)
          )
          : [];
    },
    selectAllProducts(i) {
      this.form.scenarios[i].products = this.filteredProducts(i).map(product => product.id);
    },
    selectAllPurchasedProducts(i) {
      this.form.scenarios[i].purchased_products = this.filteredProducts(i).map(product => product.id);
    },
    addScenario() {
      this.form.scenarios.push({
        name: null,
        min_product_qty: 1,

        brands: [],
        products: [],

        purchased_products: [],
        purchased_brands: [],

        gifts: JSON.parse(JSON.stringify(this.gifts.map(el => {
          el['percentage'] = null
          el['digital_amount'] = null
          el['recipient_digital_amount'] = null
          el['is_active'] = false
          return el
        }))),

      })

      this.$vuetify.goTo(document.body.scrollHeight - 350)
    },
    getProductsAndBrands() {
      this.isLoading = true
      axios.all([
        HTTP.get('/brands'),
        HTTP.get('/products')
      ]).then(axios.spread((res1, res2) => {
        this.brands = res1.data.data
        this.products = res2.data.data
        this.isLoading = false
      })).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  mounted() {
    this.getProductsAndBrands()
    this.$vuetify.goTo(0)
    let _vm = this
    setTimeout(function () {
      _vm.progress += 34
    }, 500)
  },
  computed: {
    stepVal: {
      get: function () {
        return this.step;
      },
      set: function (newValue) {
        this.$emit('update:step', newValue)
      }
    },
  }
}
</script>

<style>

</style>